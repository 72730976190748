import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {TranslatePipe} from "../../shared/pipes/translate.pipe";
import {ActivatedRoute} from "@angular/router";
import {CustomerService} from "../../shared/services/customer.service";
import {UserService} from "../../shared/services/user.service";
import {ApiService} from "../../shared/services/api.service";
import {RouterService} from "../../shared/services/router.service";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {Config} from "../../shared/config/config";
import {AsyncPipe, NgIf, NgOptimizedImage} from "@angular/common";
import {FormInputComponent} from "../../shared/elements/form-input/form-input.component";
import {ErrorService} from "../../shared/services/error.service";
import {LanguageService} from "../../shared/services/language.service";
import {LoadingService} from "../../shared/services/loading.service";
import {LayoutService} from "../../shared/services/layout.service";
import {FormButtonComponent} from "../../shared/elements/form-button/form-button.component";
import {LinkComponent} from "../../shared/elements/link/link.component";
import {FormTextareaComponent} from "../../shared/elements/form-textarea/form-textarea.component";
import {HeadlineComponent} from "../../shared/elements/headline/headline.component";
import {ButtonModule} from '@syncfusion/ej2-angular-buttons';
import {TextBoxModule} from '@syncfusion/ej2-angular-inputs';
import {TooltipModule} from "@syncfusion/ej2-angular-popups";

@Component({
  selector: 'app-test',
  standalone: true,
    imports: [
        AsyncPipe,
        FormButtonComponent,
        FormInputComponent,
        FormTextareaComponent,
        HeadlineComponent,
        LinkComponent,
        NgIf,
        NgOptimizedImage,
        ReactiveFormsModule,
        TranslatePipe,
        ButtonModule,
        TextBoxModule,
        TooltipModule,
    ],
  templateUrl: './test.component.html',
  styleUrl: './test.component.scss'
})
export class TestComponent implements OnInit, OnDestroy {
  public customerForm: FormGroup = this.formBuilder.group({
    customer: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(45)]]
  });
  public defaultStoreString = Config.DEFAULT_STORE_STRING;
  private subscriptions: Subscription[] = [];

  constructor(
    public loadingService: LoadingService,
    private apiService: ApiService,
    private customerService: CustomerService,
    private errorService: ErrorService,
    private formBuilder: FormBuilder,
    private languageService: LanguageService,
    private route: ActivatedRoute,
    private routerService: RouterService,
    private userService: UserService,
    public layoutService: LayoutService
  ) {
  }

  public ngOnInit(): void {
    const customerKey = this.customerService.getStoredCustomerKey(this.route.snapshot.params);
    if (customerKey) {
      this.customerService.setCustomerKey(customerKey);
      this.onCustomerLogin(customerKey);
    }
  }

  public ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  public onCustomerLogin(customerKey: string | null): void {
    if (customerKey) {
      this.subscriptions.push(this.apiService.getCustomer(customerKey).subscribe({
        next: (customer) => {
          this.customerService.setCustomer(customer);
          this.languageService.setCustomerLanguages(customer.languages);

          if (customer.api && typeof customer.api != null) {
            this.apiService.setUrl(customer.api);
          }

          if (this.route.snapshot.params.hasOwnProperty('token')) {
            this.userService.setToken(this.route.snapshot.params['token']);
          }
          this.routerService.navigateTo('/auth/login');
        }, error: (error) => {
          this.errorService.setError(this.languageService.getTranslationByKey('ERROR'), error, true, false);
        }
      }));
    }
  }

  public setLanguage(language: string): void {
    this.languageService.setLanguage(language);
  }
}
