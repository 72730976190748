<ng-container *ngIf="!(loadingService.loadingIndicator$ | async)">
  <!--<img [ngSrc]="'/assets/images/logos/humanstarsSmall.png'" [width]="150" [height]="150" [loaderParams]="{width: 150, height: 150}" priority />

  {{ "CUSTOMERKEY" | translate }}

  {{ "CUSTOMERHINT" | translate }}

  <form [formGroup]="customerForm" novalidate>
    <app-form-input [hint]="('CUSTOMERKEY' | translate)" [fCN]="'customer'" [fG]="customerForm" (returnKeyPress)="onCustomerLogin(customerForm.controls['customer'].value)" [errorText]="('CUSTOMERKEYREQUIRED' | translate)"></app-form-input>
    <app-form-button (click)="onCustomerLogin(customerForm.controls['customer'].value)" [disabled]="customerForm.invalid" [text]="'LOGIN' | translate"></app-form-button>
  </form>

  <a *ngIf="defaultStoreString === 'store'" [routerLink]="'/auth/registration'">{{ "TOCUSTOMERREGISTRATION" | translate }}</a>

  <div><button class="nav-link" (click)="setLanguage('hs')">HS</button></div>

  <div><button class="nav-link" (click)="setLanguage('en')">EN</button></div>

  <div><button class="nav-link" (click)="setLanguage('de')">DE</button></div>-->


  <div id="app-test" class="main-container d-flex flex-column p-3" role="main" aria-label="Login Page">
    <!-- Navbar for Top Bar Links with Hamburger Menu for Mobile -->
    <nav aria-label="Main Navigation" class="navbar navbar-expand-lg navbar-light w-100">
      <div class="container-fluid">
        <!-- Hamburger Menu Button aligned to the right -->
        <button class="navbar-toggler ms-auto" type="button" data-bs-toggle="dropdown" aria-expanded="false" aria-label="Toggle Navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <!-- Dropdown Menu -->
        <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
          <li class="dropdown-item"><app-link [href]="'/auth/password'" [title]="('TOPWFORGOT' | translate)" [style]="'nav'">{{ ('TOPWFORGOT' | translate) }}</app-link></li>
        </ul>
        <!-- Navbar Links for Desktop View -->
        <div class="collapse navbar-collapse justify-content-end">
          <ul class="navbar-nav d-none d-lg-flex">
            <li class="nav-item"><app-link [href]="'/auth/signup'" [title]="('TOSIGNUP' | translate)" [style]="'nav'">{{ ('TOSIGNUP' | translate) }}</app-link></li>
            <li class="nav-item"><app-link [href]="'/auth/password'" [title]="('TOPWFORGOT' | translate)" [style]="'nav'">{{ ('TOPWFORGOT' | translate) }}</app-link></li>        
          </ul>
        </div>
      </div>
    </nav>
    <div class="inner-container d-flex justify-content-center flex-grow-1">
      <!-- Content Row with Two Columns -->
      <div class="row w-100 h-100">
        <!-- Left Column for Logo -->
        <div class="col-xl-6 logo d-flex justify-content-center align-items-center">
          <img (click)="layoutService.toggleTheme()" [src]="layoutService.getTheme() === 'dark' ? '/assets/images/logos/humanstarsFullWhite.png':'/assets/images/logos/humanstarsFullGrey.png'" alt="Company Logo" class="img-fluid">
        </div>
        <!-- Right Column for Form -->
        <div class="col-xl-6 login-form custom-scrollbar vh-50">
          <div class="scroll-wrapper d-flex align-items-center justify-content-center flex-column w-100" >
          <form class="form-container w-100" aria-labelledby="signInHeading">

            <app-headline [type]="'h4'" [title]="('LOGIN' | translate)"></app-headline>

            <!-- Form Inputs -->
            <div class="mb-3">
              <div>Add or remove the class 'hover-effect'</div>
              <div class="e-float-input e-input-group e-float-icon-left e-error hover-effect">
                <span class="e-input-group-icon mts-icon">person</span>
                <div class="e-input-in-wrap asterisk">
                      <input type="text" class="e-input" required aria-placeholder="User Name" aria-labelledby="User Name"/>
                      <span class="e-float-line"></span>
                      <label class="e-float-text">User Name</label>
                      <span class="e-input-group-icon mts-icon">group</span>
                </div>
                
              </div>
              <div class="error-label" role="alert">
                  Please enter a valid User Name.
              </div>
              
              <div class="e-float-input e-input-group e-float-icon-left hover-effect">
                <span class="e-input-group-icon mts-icon">person</span>
                <div class="e-input-in-wrap asterisk">
                      <input type="text" class="e-input" required aria-placeholder="User Name" aria-labelledby="User Name"/>
                      <span class="e-float-line"></span>
                      <label class="e-float-text">User Name</label>
                </div>
              </div>

              <div class="e-float-input e-input-group">
                <div class="e-input-in-wrap asterisk">
                      <input type="text" class="e-input" required aria-placeholder="User Name" aria-labelledby="User Name"/>
                      <span class="e-float-line"></span>
                      <label class="e-float-text">User Name</label>
                      <span class="e-input-group-icon mts-icon">group</span>
                </div>
              </div>

              <div class="e-float-input e-input-group">
                <div class="e-input-in-wrap asterisk">
                      <input type="text" class="e-input" required aria-placeholder="User Name" aria-labelledby="User Name"/>
                      <span class="e-float-line"></span>
                      <label class="e-float-text" >User Name</label>
                </div>
              </div>

              <div class="e-float-input e-input-group">
                <div class="e-input-in-wrap asterisk">
                      <input type="text" class="e-input" aria-placeholder="User Name" aria-labelledby="User Name"/>
                      <span class="e-float-line"></span>
                      <label class="e-float-text">User Name</label>
                </div>
              </div>

              <input type="text" class="e-input" required aria-placeholder="User Name" aria-labelledby="User Name" placeholder="User Name *"/>

              <textarea class="e-input" placeholder="Address"></textarea>

                <div class="e-float-input">
                    <textarea required></textarea>
                    <span class="e-float-line"></span>
                    <label class="e-float-text"> Address </label>
                </div>

              

            </div>
            <div class="input-group mb-3">
              <span class="input-group-text">$</span>
              <input type="text" class="form-control" aria-label="Amount (to the nearest dollar)">
              <span class="input-group-text">.00</span>
            </div>
            <app-form-button [text]="'LOGIN' | translate" [disabled]="customerForm.invalid" (click)="onCustomerLogin(customerForm.controls['customer'].value)" [style]="'block'"></app-form-button>
            <button ejs-button cssClass="e-flat w-100">FLAT</button>
          </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- App Store Buttons at the Bottom Right -->
  <div class="app-store-buttons">
    <app-link [href]="'https://play.google.com/store/apps/details?id=com.maxtoolbox.blau'" [target]="'_blank'" [title]="'Google Play'">
      <img [ngSrc]="'/assets/images/logos/google.png'" [alt]="'Google Play'" [width]="134" [height]="40" [loaderParams]="{width: 134, height: 40}" priority />
    </app-link>
    <app-link [href]="'https://apps.apple.com/de/app/humanstars-employee-app/id1343223126'" [target]="'_blank'" [title]="'App Store'">
      <img [ngSrc]="'/assets/images/logos/apple.png'" [alt]="'App Store'" [width]="132" [height]="40" [loaderParams]="{width: 132, height: 40}" priority />
    </app-link>
  </div>
</ng-container>
